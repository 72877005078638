/* src/components/LocationList.css */

.section {
    padding: 20px;
  }
  
  .location-list {
    list-style-type: none;
    padding: 0;
  }
  
  .location-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .location-description {
    cursor: pointer;
    flex-grow: 1;
    margin-right: 10px;
  }
  
  .qr-code-small {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  
  .qr-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .qr-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
  }
  
  .qr-code-large {
    max-width: 100%;
    height: auto;
  }
  
  /* Laadindicatorstijl */
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }  