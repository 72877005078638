/* src/components/Sidebar.css */

.sidebar {
  height: 100vh;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #343a40;
  padding-top: 20px;
  color: white;
  transition: width 0.3s;
}

.sidebar-header {
  padding: 10px;
  text-align: center;
  font-size: 1.5em;
  color: #ffffff;
}

.nav-link {
  color: white;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  text-decoration: none;
}

.nav-link .nav-icon {
  margin-right: 10px;
}

.nav-link:hover {
  background-color: #495057;
  border-radius: 0;
}

.nav-link.active {
  background-color: #495057;
  border-left: 4px solid #007bff;
  border-radius: 0;
  color: white;
}

.content {
  margin-left: 250px; /* Maak ruimte voor de zijbalk */
  padding: 20px;
  transition: margin-left 0.3s;
}

.App-header {
  background-color: #343a40;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
}

.container {
  padding: 20px;
}

.section {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

label {
  text-align: left;
  font-weight: bold;
}

input[type="text"],
input[type="number"],
select {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px;
  font-size: 16px;
  background-color: #343a40;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #495057;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .sidebar .nav-link {
    padding: 10px;
  }

  .content {
    margin-left: 0;
  }

  .container {
    padding: 10px;
  }

  .section {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
  }
}