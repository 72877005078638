/* src/components/LocationDetails.css */

.location-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.qr-code-container {
  margin-bottom: 20px;
}

.qr-code-container img {
  width: 150px;
  height: 150px;
}

.products-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stock-control {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.stock-control button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.stock-control button:hover {
  background-color: #0056b3;
}

.stock-control input {
  width: 60px;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}



@media (max-width: 768px) {
  .location-details {
    padding: 10px;
  }

  .qr-code-container img {
    width: 100px;
    height: 100px;
  }

  .products-container {
    grid-template-columns: 1fr;
  }

  .stock-control button {
    padding: 5px 8px;
    font-size: 14px;
  }

  .stock-control input {
    width: 50px;
    font-size: 14px;
  }
}